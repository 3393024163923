import userHasScopes from "@components/VisibleForScopes/userHasScopes";
import Accordion from "@components/Ui/Accordion";
import Column from "@components/Ui/Column";
import Icon from "@components/Ui/Icon";
import Row from "@components/Ui/Row";
import Clickable from "@components/Ui/Clickable";
import { useSesamyAuthTokenContext } from "@contexts/sesamy-auth-token-context";
import { useVendorContext } from "@contexts/vendor-context";
import { DynamicObject } from "@type-defs/common";
import { NavItem } from "@type-defs/navigation";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { IconName } from "icons/types";
import { preserveQueryString } from "@utils/query-string";
import { useRouter } from "next/router";

type Props = {
  navItem: NavItem;
  expanded: boolean;
  activePageId: string;
  activeSubPageId: string;
  navItemsOpenedState: [DynamicObject, Function];
};

export default ({
  navItem: { id, icon, subItems },
  expanded,
  activePageId,
  activeSubPageId,
  navItemsOpenedState,
}: Props) => {
  const [navItemsOpened, setNavItemsOpened] = navItemsOpenedState;

  const { selectedVendor } = useVendorContext();

  const { t } = useTranslation();
  const { asPath } = useRouter();
  const { scopesWeHaveForVendor } = useSesamyAuthTokenContext();

  if (!scopesWeHaveForVendor) return null;

  const openSubmenu = () => {
    const toggled = !navItemsOpened[id];

    setNavItemsOpened((navItems: DynamicObject) =>
      Object.entries(navItems).reduce(
        (a, [key, value]) => ({
          ...a,
          [key]: key === id ? toggled : toggled ? false : value,
        }),
        {},
      ),
    );
  };

  const filteredSubItems = subItems.filter(({ scopes, id }) =>
    userHasScopes(scopes, scopesWeHaveForVendor),
  );

  const multipleItems = filteredSubItems.length > 1;

  const subMenuOpen = navItemsOpened[id] && expanded && multipleItems;

  return (
    <div className="group relative w-full" data-testid={id}>
      <Clickable
        onClick={openSubmenu}
        href={
          !multipleItems
            ? `/${selectedVendor}${subItems[0].href}${preserveQueryString(asPath)}`
            : undefined
        }
        className={twMerge(
          "relative w-10 !flex-nowrap !justify-between overflow-hidden rounded px-2.5 py-2 transition-[background-color,width] duration-300 row group-hover:cursor-pointer group-hover:bg-gray-600",
          expanded && "w-full",
          id === activePageId && "bg-gray-600",
        )}
      >
        <Row className="!flex-nowrap gap-4">
          <Icon
            className={twMerge(
              "text-xl text-gray-300 transition-colors duration-300 group-hover:text-white sm:text-xl",
              id === activePageId && "text-white",
            )}
            name={icon as IconName}
          />
          <span
            className={twMerge(
              "text-base font-medium leading-[0] opacity-0 transition-opacity duration-300 sm:text-base",
              expanded && "opacity-100",
            )}
          >
            {t(id)}
          </span>
        </Row>
        {multipleItems && (
          <Icon
            className={twMerge(
              "scale-y-1 mr-1 text-sm text-gray-300 opacity-0 transition-[opacity,transform] duration-75 sm:text-sm",
              expanded && "opacity-100",
              subMenuOpen && "-translate-y-px scale-y-[-1]",
            )}
            name="chevron-down"
          />
        )}
      </Clickable>

      {multipleItems && (
        <Accordion isOpen={subMenuOpen}>
          <Column className="w-full gap-1.5 pl-9 pt-2" left>
            {filteredSubItems.map(({ id, href }) => (
              <Clickable
                key={`accordion-${id}`}
                href={`/${selectedVendor}${href}${preserveQueryString(asPath)}`}
                className={twMerge(
                  "w-full rounded px-3 py-2.5 text-sm text-gray-300 transition-colors duration-100 hover:cursor-pointer hover:bg-gray-700 hover:text-white sm:text-sm",
                  id === activeSubPageId && "bg-gray-700 text-white",
                )}
              >
                {t(id)}
              </Clickable>
            ))}
          </Column>
        </Accordion>
      )}

      {!expanded && (
        <div
          className={twMerge(
            "pointer-events-none absolute left-full top-0 z-0 -translate-x-2 -translate-y-5 p-5 pl-2 opacity-0 transition-[opacity,transform] duration-500 group-hover:pointer-events-auto group-hover:z-20 group-hover:translate-x-0 group-hover:opacity-100",
            expanded &&
              "pointer-events-none opacity-0 group-hover:pointer-events-none group-hover:opacity-0",
          )}
        >
          <Column className="overflow-hidden rounded-lg bg-gray-600" left up>
            {filteredSubItems.map(({ id, href }) => (
              <Clickable
                key={`tooltip-${id}`}
                href={`/${selectedVendor}${href}${preserveQueryString(asPath)}`}
                className={twMerge(
                  "w-full whitespace-nowrap px-4 py-3 text-sm hover:bg-gray-700 sm:text-sm",
                  id === activeSubPageId && "bg-gray-700",
                )}
              >
                {t(id)}
              </Clickable>
            ))}
          </Column>
        </div>
      )}
    </div>
  );
};
