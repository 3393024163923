const userHasScopes = (scopes: string[], scopesWeHaveForVendor: string[]) => {
  if (
    !scopes.length ||
    scopes.every((scope) => scopesWeHaveForVendor.includes(scope))
  ) {
    return true;
  }

  return false;
};

export default userHasScopes;
