import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export default ({
  up = false,
  right = false,
  down = false,
  left = false,
  children,
  className = "",
  ...restProps
}: {
  up?: boolean;
  right?: boolean;
  down?: boolean;
  left?: boolean;
  children: ReactNode;
  className?: string;
  [x: string]: any;
}) => (
  <div
    className={twMerge(
      !up && !right && !down && !left && "row",
      up && !right && !down && !left && "row-up",
      !up && right && !down && !left && "row-right",
      !up && !right && down && !left && "row-down",
      !up && !right && !down && left && "row-left",
      up && right && !down && !left && "row-up-right",
      up && !right && !down && left && "row-up-left",
      !up && right && down && !left && "row-down-right",
      !up && !right && down && left && "row-down-left",
      className,
    )}
    {...restProps}
  >
    {children}
  </div>
);
