import { useAuth0 } from "@auth0/auth0-react";
import { baseRedirectUri } from "@utils/auth";
import { twMerge } from "tailwind-merge";

type Props = {
  text: string;
  className?: string;
};

export default ({ text, className = "" }: Props) => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: baseRedirectUri,
      },
    });
  };

  return (
    <button
      className={twMerge(
        "cursor-pointer text-primary hover:text-purple-500",
        className,
      )}
      onClick={handleLogout}
    >
      {text}
    </button>
  );
};
