import { ReactNode } from "react";
import { useSpring, animated } from "react-spring";

const FlyIn = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const props = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  });

  return (
    <animated.div style={props} className={className}>
      {children}
    </animated.div>
  );
};

export default FlyIn;
