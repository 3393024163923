const en = {
  translation: {
    date: "date",
    bundle: "bundle",
    netSalesInSek: "net sales (kr)",
    netSalesInEur: "net sales (€)",
    newSubscriptions: "New subscriptions",
    cancelledSubscriptions: "Canceled subscriptions",
    churnRate: "churn rate (%)",
    "payment.add_a_new_card": "New card",
    n_items: "Number of items",
    n_podcast: "n of podcasts",
    n_audiobook: "n of audiobooks",
    n_ebook: "n of ebooks",
    totalSales: "Total sales",
    churn_rate: "Churn rate",
    endedSubscriptions: "Ended subscriptions",
    totalSubscriptions: "Total subscriptions",
    total_subscribers: "Total subscribers",
    new_card: "New card",
    select_your_vendor: "Select your vendor",
    contact_support: "Need Help?",
    copyright_sesamy: "©2023 Sesamy",
    vendor_access:
      "Your user has access to the following vendors. Please, choose the data you’d like to analyse.",
    sign_out: "Sign out",
    use_another: "I want to use another account",
    follow_real_time: "Follow your data in real time.",
    more_revenue: "Whilst getting you more revenue.",
    vendor_logo: "{{vendor}}'s logotype",
    type_to_filter: "Type to filter",
    no_vendor_with_filter: "No vendor with that filter",
    analytics: "Analytics",
    overview: "Overview",
    data_management: "Data Management",
    customers: "Customers",
    reports: "Reports",
    discounts: "Discounts",
    discount: "Discount",
    podcasts: "Podcasts",
    paywalls: "Paywalls",
    general: "General",
    single_purchase: "Single Purchase",
    shows: "Shows",
    purchaseOptions: "Purchase options",
    add_paywall: "Add paywall",
    paywall_settings: "Paywall settings",
    discount_stats: "Discount stats",
    subscriptions: "Subscriptions",
    single_purchases: "Single Purchases",
    external_customers: "External Customers",
    contracts: "Contracts",
    contract: "Contract",
    search: "Search",
    tools: "Tools",
    privacy_policy: "Privacy Policy",
    terms_and_conditions: "Terms & Conditions",
    hi: "Hi",
    welcome_to: "Welcome to",
    dashboard: "dashboard",
    support_people: "picture showing support people avatars",
    generate_report: "Generate report",
    created_reports: "Created reports",
    required: "required",
    is_required: "is required",
    must_be_before_to_date: '"From" date must be before "To" date',
    must_be_after_from_date: '"To" date must be after "From" date',
    fromDate: "From",
    toDate: "To",
    choose_data: "Choose the data you’d like to export",
    payment_method: "Payment method",
    status: "Status",
    amount: "Amount",
    create: "Create",
    update: "Update",
    cancel: "Cancel",
    continue: "Continue",
    skip: "Skip",
    create_contract: "Create contract",
    contract_setup_completed:
      "The contract setup is now completed. You can send the checkout link to the customer for them to validate the information and complete the purchase.",
    allowed_create_contract:
      "The selected payment method allows you to complete the contract right away.",
    click_create_contract_to_complete:
      'Click on "Create contract" to complete the creation. The contract will be created right away and the payment instructions will be sent to the customer.',
    complete_contract_now: "Complete the contract right away",
    deliveries: "Deliveries",
    settings: "Settings",
  },
};

export default en;
