import { ReactNode } from "react";
import { SWRConfig } from "swr";

type SWRWrapperProps = {
  children: ReactNode;
};

export default ({ children }: SWRWrapperProps) => {
  return (
    <SWRConfig
      value={{
        refreshInterval: 30 * 1000,
      }}
    >
      {children}
    </SWRConfig>
  );
};
