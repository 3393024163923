import React, { useEffect, useState, useRef } from "react";
import Icon from "@components/Ui/Icon";
import FlyIn from "@components/ReactSpring/FlyIn";
import { Notifications } from "@type-defs/notifications";
import { twMerge } from "tailwind-merge";
import Row from "@components/Ui/Row";

type AnimationEvent = {
  target: {
    effect: {
      target: HTMLElement;
    };
  };
};

export default () => {
  const [animations, setAnimations] = useState<any>([]);
  const [animating, setAnimating] = useState(false);
  const [notifications, setNotifications] = useState<Notifications>(new Set());

  const ref = useRef<any>();

  const addNotification = ({ detail: notification }: any) =>
    setNotifications((notifications) =>
      new Set(notifications).add(JSON.stringify(notification)),
    );

  useEffect(() => {
    document.addEventListener("add-notification", addNotification);

    return () =>
      document.removeEventListener("add-notification", addNotification);
  }, []);

  useEffect(() => {
    const removeFirst = (items: Notifications) => {
      if (!ref.current || !ref.current.children[0]) return;

      const { height } = ref.current.children[0].getBoundingClientRect();

      if (items.size && !animating && height) {
        const timing = {
          delay: 3000,
          duration: 600,
          easing: "ease",
        };

        setAnimations(() => [
          ref.current.animate(
            {
              transform: `translateY(${height}px)`,
            },
            timing,
          ),
          ref.current.children[0].animate(
            {
              opacity: 0,
            },
            {
              ...timing,
              fill: "forwards",
            },
          ),
        ]);

        setAnimating(true);
      }
    };

    if (ref && ref.current) {
      removeFirst(notifications);
    }
  }, [ref, notifications, animating]);

  useEffect(() => {
    if (animations.length) {
      animations[1].onfinish = ({
        target: {
          effect: { target },
        },
      }: AnimationEvent) => {
        requestAnimationFrame(() => {
          target.style.height = "0px";
          setAnimations([]);
          setAnimating(false);

          setNotifications((notifications) => {
            const tempSet = new Set(notifications);
            tempSet.delete(notifications.values().next().value);
            return tempSet;
          });
        });
      };
    }
  }, [animations, notifications, setNotifications]);

  return (
    <div className="pointer-events-none fixed bottom-0 left-1/2 z-20 w-[calc(100vw-theme(space.6))] max-w-[920px] -translate-x-1/2 sm:bottom-2">
      <div className="w-full !flex-col-reverse column" ref={ref}>
        {[...notifications].map((notification) => {
          const { type, title, message } = JSON.parse(notification);
          return (
            <FlyIn key={notification} className="w-full">
              <div
                className={twMerge(
                  "mb-2 w-full rounded-md px-4 py-3 text-sm leading-normal text-white",
                  type === "error" && "bg-[#E12121]",
                  type === "information" &&
                    "bg-black dark:bg-white dark:text-black",
                  type === "warning" && "bg-[#EC980C]",
                  type === "success" && "bg-[#019939]",
                )}
              >
                <Row className="transform-gpu gap-2" up left>
                  <Icon className="text-base !leading-[21px]" name="info" />
                  <Row className="flex-1 gap-2" left>
                    {title && (
                      <div className="font-medium first-letter:capitalize">
                        {title}
                      </div>
                    )}
                    <div
                      className={twMerge(
                        "first-letter:capitalize",
                        title && "text-gray-100/80",
                      )}
                    >
                      {message}
                    </div>
                  </Row>
                </Row>
              </div>
            </FlyIn>
          );
        })}
      </div>
    </div>
  );
};
