import { ParsedUrlQuery } from "querystring";

export function flattenQueryStringKey(query: ParsedUrlQuery, key: string) {
  const val = query[key];

  const flatVal = Array.isArray(val) ? val[0] : val;

  return flatVal;
}

export function preserveQueryString(path: string) {
  if (path.includes("?")) {
    const [, queryString] = path.split("?");

    const currentParams = new URLSearchParams(queryString);
    const preservedParams = new URLSearchParams();

    const startDate = currentParams.get("start-date");
    startDate && preservedParams.set("start-date", startDate);

    const endDate = currentParams.get("end-date");
    endDate && preservedParams.set("end-date", endDate);

    const newQueryString = preservedParams.toString();

    return startDate || endDate ? `?${newQueryString}` : "";
  }

  return "";
}
