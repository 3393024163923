import ForbiddenPage from "@components/Forbidden/Forbidden";
import { useVendorContext } from "@contexts/vendor-context";

type Props = {
  children: React.ReactNode;
};

export default ({ children }: Props) => {
  const { vendors } = useVendorContext();

  // need to render inner child component as this contains the page which sets the authtoken in state
  if (!vendors || vendors.length > 0) {
    return <>{children}</>;
  } else {
    return <ForbiddenPage />;
  }
};
