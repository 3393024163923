import { IconName } from "icons/types";
import icons from "icons/icons.json";
import { twMerge } from "tailwind-merge";

type Props = {
  name: IconName;
  multiColor?: boolean;
  ratio?: number;
  className?: string;
  [x: string]: any;
};

export default ({
  name,
  multiColor = false,
  ratio = 1,
  className = "",
  ...restProps
}: Props) => {
  if (!icons[name]) return null;
  const {
    elements,
    fills,
    strokes,
    viewBoxWidth,
    viewBoxHeight,
    widthRatio,
    heightRatio,
  } = icons[name];

  const colors = multiColor
    ? (
        [
          ...fills.map(
            (fill, i) => fill && [`--${name}-fill-color-${i}`, fill],
          ),
          ...strokes.map(
            (stroke, i) => stroke && [`--${name}-stroke-color-${i}`, stroke],
          ),
        ].filter(Boolean) as [string, string][]
      ).reduce((a, [key, value]) => ({ ...a, [key]: value }), {})
    : {};

  return (
    <svg
      className={twMerge(`icon-${name}`, className)}
      viewBox={
        viewBoxWidth && viewBoxHeight
          ? `0 0 ${viewBoxWidth} ${viewBoxHeight}`
          : undefined
      }
      style={colors}
      width={`${ratio * widthRatio}em`}
      height={`${ratio * heightRatio}em`}
      dangerouslySetInnerHTML={{ __html: elements.join("") }}
      {...restProps}
    />
  );
};
