import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";

type ProviderProps = {
  children: React.ReactNode;
};

type AuthTokenContextType = {
  accessToken?: string;
  isLoading: boolean;
  checkSession: () => void;
};

// note that this is actually a sesamy token now BUT this is the sesamy token without a vendor set or permissions request
// then the SesamyAuthTokenContext inside this is another sesamy token with a vendor set and permissions
const AuthTokenContext = createContext<AuthTokenContextType>({
  isLoading: true,
  checkSession: () => {},
});

export const AuthTokenProvider = ({ children }: ProviderProps) => {
  const [accessToken, setAccessToken] = useState<string>();

  const { isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const checkSession = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (err) {
      console.error(err);

      const returnToUrl = window.location.href;

      loginWithRedirect({
        authorizationParams: {
          redirect_uri: returnToUrl,
        },
      });
    }
  }, [getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  return (
    <AuthTokenContext.Provider
      value={{
        accessToken,
        isLoading,
        checkSession,
      }}
    >
      {children}
    </AuthTokenContext.Provider>
  );
};

// maintaining backwards compatibility
export const useAuthTokenContext = () => {
  const context = useContext(AuthTokenContext);

  if (!context.accessToken) return {};

  const authToken = context.accessToken;

  return { authToken };
};

export const useSessionContext = () => useContext(AuthTokenContext);
