import Link from "next/link";
import {
  ReactNode,
  MouseEventHandler,
  PropsWithChildren,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  HTMLAttributes,
} from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  onClick?: MouseEventHandler<HTMLElement>;
  children: ReactNode;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  HTMLAttributes<HTMLDivElement>;

export default ({
  onClick,
  children,
  className = "",
  ...rest
}: PropsWithChildren<Props>) => {
  const commonProps = {
    onClick,
    className: twMerge(
      "border-0 bg-none outline-none outline-offset-0",
      className,
    ),
    ...rest,
  };

  const { href } = rest;

  return href ? (
    <Link href={href} className={className}>
      {children}
    </Link>
  ) : onClick ? (
    <button {...commonProps}>{children}</button>
  ) : (
    <div {...commonProps}>{children}</div>
  );
};
