import styled from "styled-components";

const StyledForbidden = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default StyledForbidden;
