import { useTranslation } from "react-i18next";
import React from "react";
import StyledForbidden from "@components/Forbidden/Forbidden.style";
import LogoutButton from "@components/LogoutButton";

export default () => {
  const { t } = useTranslation();
  return (
    <StyledForbidden>
      <div className="text-4xl font-semibold">403 Forbidden:</div>
      <div className="text-2xl">
        We are sorry to say your user lacks permissions to visit this area
      </div>

      <div className="button-container">
        <LogoutButton text={t("sign_out")} />
      </div>
    </StyledForbidden>
  );
};
