import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export default ({
  children,
  isOpen = false,
  className = "",
}: {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
}) => (
  <div
    className={twMerge(
      "grid w-full transition-[grid-template-rows] duration-200 ease-out",
      !isOpen && "grid-rows-[0fr]",
      isOpen && "grid-rows-[1fr]",
      className,
    )}
  >
    <div className="w-full overflow-hidden">{children}</div>
  </div>
);
