import { createContext, useState } from "react";
import _Modal from "@components/Modal/_Modal";

type ProviderProps = {
  children: React.ReactNode;
};

type ModalContextType = {
  isOpen: boolean;
  content: React.ReactNode | null;
  openModal: (content: React.ReactNode, backdrop?: boolean) => void;
  closeModal: () => void;
};

export const ModalContentContext = createContext<ModalContextType>({
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
  content: null,
});

export const ModalContentProvider = ({ children }: ProviderProps) => {
  const [content, setContent] = useState<ModalContextType["content"]>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasBackdrop, setHasBackdrop] = useState(false);

  const openModal = (content: React.ReactNode, backdrop?: boolean) => {
    backdrop ? setHasBackdrop(true) : setHasBackdrop(false);
    setContent(content);
    setIsOpen(true);
  };

  const closeModal = () => {
    setContent(null);
    setIsOpen(false);
  };

  return (
    <ModalContentContext.Provider
      value={{ content, isOpen, openModal, closeModal }}
    >
      {children}
      <_Modal backdrop={hasBackdrop} />
    </ModalContentContext.Provider>
  );
};
