import {
  useAuthTokenContext,
  useSessionContext,
} from "@contexts/auth-token-context";

export default function useAuthFetch() {
  // TODO - initially can be null...  need to be sure this works
  const { authToken } = useAuthTokenContext();
  const { checkSession } = useSessionContext();

  const fetcher = async (url: string) => {
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (res.status > 400 && res.status < 500) {
      await checkSession();

      // throw an error here so that SWR retries
      throw new Error("Access denied");
    }

    const body = await res.json();

    return body;
  };

  const isReady = !!authToken;

  return { fetcher, isReady };
}
