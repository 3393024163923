import { useRef, useContext } from "react";
import { ModalContentContext } from "@contexts/modal-context";

export default ({ backdrop }: { backdrop: boolean }) => {
  const ref = useRef(null);
  const { isOpen, closeModal, content } = useContext(ModalContentContext);
  return isOpen ? (
    <>
      {backdrop && (
        <div className="fixed left-0 top-0 h-full w-full bg-black opacity-70" />
      )}
      <div
        className="fixed left-1/2 top-[15%] z-50 w-full max-w-xl -translate-x-1/2 rounded-lg bg-gray-800 pb-5 pt-12"
        ref={ref}
      >
        <button
          className="absolute right-3 top-1.5 bg-transparent text-2xl"
          onClick={closeModal}
        >
          &times;
        </button>
        {content}
      </div>
    </>
  ) : null;
};
