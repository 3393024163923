import i18next from "i18next";
import resources from "./resources";

export const createI18n = (language: string) => {
  const i18n = i18next.createInstance();
  i18n.init({
    lng: language,
    fallbackLng: language,
    resources,
  });
  return i18n;
};
