import localPlaywrightUrl from "../local-playwright-url";

const getUrlByEnvironment = () => {
  if (!!process.env.NEXT_PUBLIC_VERCEL_URL) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }

  if (localPlaywrightUrl.includes(":3000")) {
    return localPlaywrightUrl;
  }

  return "http://localhost:3000";
};

export const baseRedirectUri = getUrlByEnvironment();
