import { createContext, useContext } from "react";
import { format, subMonths } from "date-fns";
import { Range as RangeDate } from "react-date-range";
import { NextRouter } from "next/router";
import { useRouter } from "next/router";
import { flattenQueryStringKey } from "@utils/query-string";

type ProviderProps = {
  children: React.ReactNode;
};

type FormattedDates = {
  startDate: string;
  endDate: string;
};

type Dates = {
  startDate: Date;
  endDate: Date;
};

type SelectedDatesContextType = {
  selectedDates: Dates;
  formattedDates: FormattedDates;
  handleDatesUpdate: (dates: RangeDate) => void;
};

const initDates = {
  startDate: subMonths(new Date(), 1),
  endDate: new Date(),
};

const initFormattedDates = getFormattedDates(initDates);

const SelectedDatesContext = createContext<SelectedDatesContextType>({
  selectedDates: initDates,
  handleDatesUpdate: () => {},
  formattedDates: initFormattedDates,
});

function getFormattedDates(dates: Dates) {
  const startDate = !!dates.startDate
    ? format(dates.startDate, "yyyy-MM-dd")
    : "";
  const endDate = !!dates.endDate ? format(dates.endDate, "yyyy-MM-dd") : "";
  const formattedDates = {
    startDate,
    endDate,
  };

  return formattedDates;
}

const START_DATE_KEY = "start-date";
const END_DATE_KEY = "end-date";

function setDates(
  router: NextRouter,
  startDate: string,
  endDate: string,
  replace?: boolean,
) {
  const querystring = { ...router.query };

  delete querystring[START_DATE_KEY];
  delete querystring[END_DATE_KEY];

  const newQueryString = {
    ...querystring,
    [START_DATE_KEY]: startDate,
    [END_DATE_KEY]: endDate,
  };
  if (replace) {
    router.replace({
      pathname: router.pathname,
      query: newQueryString,
    });
  } else {
    router.push({
      pathname: router.pathname,
      query: newQueryString,
    });
  }
}

export const SelectedDatesProvider = ({ children }: ProviderProps) => {
  const router = useRouter();
  const { query } = router;

  const startDate =
    flattenQueryStringKey(query, START_DATE_KEY) ||
    initFormattedDates.startDate;
  const endDate =
    flattenQueryStringKey(query, END_DATE_KEY) || initFormattedDates.endDate;

  // is this re-rendered all the time? careful with these objects
  const formattedDates: FormattedDates = {
    startDate,
    endDate,
  };

  const selectedDates: Dates = {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };

  const handleDatesUpdate = (dates: RangeDate) => {
    const { startDate, endDate } = dates;
    // so initial state must have start and end date
    // but this uncontrolled date selector can pick unfinished ranges
    // I think we ignore incomplete ranges... let's see
    if (!!startDate && !!endDate) {
      const newFormattedDates = getFormattedDates({ startDate, endDate });
      setDates(router, newFormattedDates.startDate, newFormattedDates.endDate);
    }
  };

  return (
    <SelectedDatesContext.Provider
      // then also this object will always be re-rendering
      value={{
        handleDatesUpdate,
        selectedDates,
        formattedDates,
      }}
    >
      {children}
    </SelectedDatesContext.Provider>
  );
};

export const useSelectedDatesContext = () => useContext(SelectedDatesContext);
